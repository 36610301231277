import { useState, useEffect, useRef, useLayoutEffect } from "react";

export const useWindowResize = () => {
	const [isMobileView, setMobileView] = useState(true);
	const [width, setWidth] = useState(768);

	useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth);
			if (window && window.innerWidth < 769) {
				setMobileView(true);
			} else {
				setMobileView(false);
			}
		};
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});
	return [isMobileView, width];
};

const getScrollPosition = ({ element, useWindow }) => {
	const isBrowser = typeof window !== "undefined";

	if (!isBrowser) return { x: 0, y: 0 };

	const target = element ? element.current : document.body;
	const position = target.getBoundingClientRect();

	return useWindow
		? { x: window.scrollX, y: window.scrollY }
		: { x: position.left, y: position.top };
};

export const useScrollPosition = (effect, deps, element, useWindow, wait) => {
	const position = useRef(getScrollPosition({ useWindow }));

	let throttleTimeout = null;

	const callback = () => {
		const currPos = getScrollPosition({ element, useWindow });
		effect({ prevPos: position.current, currPos });
		position.current = currPos;
		throttleTimeout = null;
	};

	useLayoutEffect(() => {
		const handleScroll = () => {
			if (wait) {
				if (throttleTimeout === null) {
					throttleTimeout = setTimeout(callback, wait);
				}
			} else {
				callback();
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => window.removeEventListener("scroll", handleScroll);
	}, deps);
};

export const usePlayWhenInViewPort = (selector) => {
	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				// eslint-disable-next-line no-unused-expressions
				entry.intersectionRatio > 0.004
					? entry.target.play()
					: entry.target.pause();
			});
		});

		const videos = document.querySelectorAll(selector);

		videos.forEach((video) => {
			observer.observe(video);
		});

		return () => {
			observer.disconnect();
		};
	}, []);
};
