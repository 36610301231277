/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-curly-newline */
import { useEffect, useState } from "react";
import styled from "styled-components";
import { getUrlPath, slugifyDepartment } from "utils/helpers";
import departmentData from "cms/department-data";
import DownArrowIcon from "assets/svgs/DownArrowIcon";
import BrandLines from "components/BrandLines";
import { bp } from "theme";
import cityData from "cms/city-data";
import UpArrowIcon from "assets/svgs/UpArrowIcon";
import Link from "next/link";
import { isUserLoggedIn } from "utils";
import FooterSocialContainer from "components/ResponsiveFooterDark/FooterSocialContainer";
import useJobFeedStore from "components/RevampFeedDetails/JobFeed/store";
import CollapseFooterLinks from "./CollapseFooterLinks";
import FooterSocialContainerV2 from "./FooterSocialContainerV2";

const topJobsList1 = [
	{
		label: "Delivery Person Jobs",
		url: "/jobs/delivery_person-jobs",
	},
	{
		label: "Accounts / Finance Jobs",
		url: "/jobs/accounts_finance-jobs",
	},
	{
		label: "Sales (Field Work)",
		url: "/jobs/sales_field_work-jobs",
	},
	{
		label: "Human Resource",
		url: "/jobs/human_resource-jobs",
	},
	{
		label: "Backoffice Jobs",
		url: "/jobs/back_office-jobs",
	},
	{
		label: "Business Development",
		url: "/jobs/business_development-jobs",
	},
	{
		label: "Telecaller / BPO",
		url: "/jobs/telecaller_bpo-jobs",
	},
];

const topJobsList2 = [
	{
		label: "Work from Home Jobs",
		url: "/jobs/work_from_home-jobs",
	},
	{
		label: "Part Time Jobs",
		url: "/jobs/part_time-jobs",
	},
	{
		label: "Full Time Jobs",
		url: "/jobs/full_time-jobs",
	},
	{
		label: "Night Shift Jobs",
		url: "/jobs/night_shift-jobs",
	},
	{
		label: "Freshers Jobs",
		url: "/jobs/freshers-jobs",
	},
	{
		label: "Jobs for Women",
		url: "/jobs/female-jobs",
	},
	{
		label: "Jobs for 10th pass",
		url: "/jobs/10th_pass-jobs",
	},
	{
		label: "Jobs for 12th pass",
		url: "/jobs/12th_pass-jobs",
	},
];

const ResponsiveFooterDarkV2 = ({ source, isLight = false, showNewFooter = false }) => {
	const [isCandidateLoggedIn, setIsCandidateLoggedIn] = useState<boolean>(false);
	const { resetSearch, resetFilters, setUrlPath } = useJobFeedStore();

	useEffect(() => {
		if (!!isUserLoggedIn() && isCandidateLoggedIn === false) {
			setIsCandidateLoggedIn(true);
		}
	}, [!!isUserLoggedIn()]);

	const trackPageLinkClick = async (link) => {
		const Mixpanel = await import("../../utils/Mixpanel");
		Mixpanel.default.track(`${link} Click`, {
			Source: source,
			page: getUrlPath(),
			Section: "Footer",
		});
	};

	const trackJobsLinkClick = async (pageType, city) => {
		const Mixpanel = await import("../../utils/Mixpanel");
		Mixpanel.default.track(`Jobs in City Click`, {
			Source: source,
			pageType,
			city,
			page: getUrlPath(),
			Section: "Footer",
		});
	};

	const trackHireInCityClick = async (pageType, city) => {
		const Mixpanel = await import("../../utils/Mixpanel");
		Mixpanel.default.track(`Hire in City Click`, {
			Source: source,
			pageType,
			city,
			page: getUrlPath(),
			Section: "Footer",
		});
	};

	const trackJobCategoryClick = async (
		pageType,
		category,
		wfh = false,
		parttime = false
	) => {
		const Mixpanel = await import("../../utils/Mixpanel");
		Mixpanel.default.track(`Jobs in Category Click`, {
			Source: source,
			pageType,
			category,
			page: getUrlPath(),
			Section: "Footer",
			wfh,
			parttime,
		});
	};

	const trackJobDepartmentClick = async (
		pageType,
		department,
		wfh = false,
		parttime = false
	) => {
		const Mixpanel = await import("../../utils/Mixpanel");
		Mixpanel.default.track(`Jobs in Department Click`, {
			Source: source,
			pageType,
			department,
			page: getUrlPath(),
			Section: "Footer",
			wfh,
			parttime,
		});
	};

	const listSomeFindJobs = () => (
		<FooterLinkContainer>
			{(cityData as any).slice(0, 12).map((city) => (
				<Text
					key={city?.name}
					onClick={() => {
						trackJobsLinkClick(`Jobs in ${city?.name}`, `${city?.name}`);
						resetSearch();
						resetFilters();
						setUrlPath({
							city_id: city?.id,
						});
					}}
				>
					<Link href={`/jobs/jobs-in-${city?.slug}`} passHref>
						<a rel="noopener noreferrer">Jobs in {city.name}</a>
					</Link>
				</Text>
			))}
		</FooterLinkContainer>
	);

	const listSomeHireInCity = () => (
		<FooterLinkContainer>
			{(cityData as any).slice(0, 12).map((city) => (
				<Text
					key={`Hire${city?.name}`}
					onClick={() => {
						trackHireInCityClick(`Hire in ${city?.name}`, `${city?.name}`);
						resetSearch();
						resetFilters();
					}}
				>
					<Link href={`/employer/job-posting-site-in-${city?.slug}`} passHref>
						<a rel="noopener noreferrer">Hire in {city?.name}</a>
					</Link>
				</Text>
			))}
		</FooterLinkContainer>
	);

	const listPopularJobs = (allJobs = false) => {
		const popularJobsList = topJobsList1.concat(topJobsList2);
		if (allJobs) {
			return (
				<FooterLinkContainer>
					{popularJobsList.map((job) => (
						<Text
							key={job.url}
							onClick={() => {
								trackJobCategoryClick(`Jobs in ${job.label}`, job.label);
								resetSearch();
								resetFilters();
							}}
						>
							<Link href={job.url} passHref>
								<a rel="noopener noreferrer">{job.label}</a>
							</Link>
						</Text>
					))}
				</FooterLinkContainer>
			);
		}
		return (
			<FooterLinkContainer>
				{popularJobsList.slice(0, 12).map((job) => (
					<Text
						key={job.url}
						onClick={() => {
							trackJobCategoryClick(`Jobs in ${job.label}`, job.label);
							resetSearch();
							resetFilters();
						}}
					>
						<Link href={job.url} passHref>
							<a rel="noopener noreferrer">{job.label}</a>
						</Link>
					</Text>
				))}
			</FooterLinkContainer>
		);
	};

	const listSomeDepartments = (list) => (
		<>
			{list.slice(0, 12).map((department) => (
				<Text
					key={slugifyDepartment(department?.name)}
					onClick={() => {
						trackJobDepartmentClick(
							`Jobs in ${department?.name}`,
							department?.name
						);
						resetSearch();
						resetFilters();
						setUrlPath({
							department_id: department?.id,
						});
					}}
				>
					<Link
						href={`/jobs/dep_${slugifyDepartment(department?.name)}-jobs`}
						passHref
					>
						<a rel="noopener noreferrer">{department?.name} Jobs</a>
					</Link>
				</Text>
			))}
		</>
	);

	const listAllDepartments = (list) => (
		<>
			{list.map((department) => (
				<Text
					key={slugifyDepartment(department?.name)}
					onClick={() => {
						trackJobDepartmentClick(
							`Jobs in ${department?.name}`,
							department?.name
						);
						resetSearch();
						resetFilters();
						setUrlPath({
							department_id: department?.id,
						});
					}}
				>
					<Link
						href={`/jobs/dep_${slugifyDepartment(department?.name)}-jobs`}
						passHref
					>
						<a rel="noopener noreferrer">{department?.name} Jobs</a>
					</Link>
				</Text>
			))}
		</>
	);

	return (
		<>
			<FooterSection isLight={isLight} className={isLight ? "!bg-[#F4F2F6]" : ""}>
				<LinkText isLight={isLight}>Find Jobs</LinkText>
				<CollapseFooterLinks
					collapseData={listSomeFindJobs()}
					unCollapseData={
						<FooterLinkContainer>
							{(cityData as any).map((city) => (
								<Text
									key={city?.id}
									onClick={() => {
										trackJobsLinkClick(
											`Jobs in ${city?.name}`,
											`${city?.name}`
										);
										resetSearch();
										resetFilters();
										setUrlPath({
											city_id: city?.id,
										});
									}}
								>
									<Link href={`/jobs/jobs-in-${city?.slug}`} passHref>
										<a rel="noopener noreferrer">
											Jobs in {city.name}
										</a>
									</Link>
								</Text>
							))}
						</FooterLinkContainer>
					}
					collapseButton={
						<ButtonContainer>
							<ViewMoreButton>
								<span>View Less</span>
								<UpArrowIcon fill="#F5F5F5" width="22" height="24" />
							</ViewMoreButton>
						</ButtonContainer>
					}
					unCollapseButton={
						<ButtonContainer>
							<ViewMoreButton>
								<span>View More</span>
								<DownArrowIcon />
							</ViewMoreButton>
						</ButtonContainer>
					}
				/>
				<Divider />
				{!isCandidateLoggedIn && (
					<>
						<LinkText isLight={isLight}>Start Hiring</LinkText>
						<CollapseFooterLinks
							collapseData={listSomeHireInCity()}
							unCollapseData={
								<FooterLinkContainer>
									{(cityData as any).map((city) => (
										<Text
											key={`Hire${city?.name}`}
											onClick={() =>
												trackHireInCityClick(
													`Hire in ${city?.name}`,
													`${city?.name}`
												)
											}
										>
											<Link
												href={`/employer/job-posting-site-in-${city?.slug}`}
												passHref
											>
												<a rel="noopener noreferrer">
													Hire in {city?.name}
												</a>
											</Link>
										</Text>
									))}
								</FooterLinkContainer>
							}
							collapseButton={
								<ButtonContainer>
									<ViewMoreButton>
										<span>View Less</span>
										<UpArrowIcon
											fill="#F5F5F5"
											width="22"
											height="24"
										/>
									</ViewMoreButton>
								</ButtonContainer>
							}
							unCollapseButton={
								<ButtonContainer>
									<ViewMoreButton>
										<span>View More</span>
										<DownArrowIcon />
									</ViewMoreButton>
								</ButtonContainer>
							}
						/>
						<Divider />
					</>
				)}
				<LinkText isLight={isLight}>Popular Jobs</LinkText>
				<CollapseFooterLinks
					collapseData={listPopularJobs()}
					unCollapseData={listPopularJobs(true)}
					collapseButton={
						<ButtonContainer>
							<ViewMoreButton>
								<span>View Less</span>
								<UpArrowIcon fill="#F5F5F5" width="22" height="24" />
							</ViewMoreButton>
						</ButtonContainer>
					}
					unCollapseButton={
						<ButtonContainer>
							<ViewMoreButton>
								<span>View More</span>
								<DownArrowIcon />
							</ViewMoreButton>
						</ButtonContainer>
					}
				/>
				<Divider />
				<LinkText isLight={isLight}>Jobs by Department</LinkText>
				<CollapseFooterLinks
					collapseData={
						<FooterLinkContainer>
							{listSomeDepartments(departmentData)}
						</FooterLinkContainer>
					}
					unCollapseData={
						<FooterLinkContainer>
							{listAllDepartments(departmentData)}
						</FooterLinkContainer>
					}
					collapseButton={
						<ButtonContainer>
							<ViewMoreButton>
								<span>View Less</span>
								<UpArrowIcon fill="#F5F5F5" width="22" height="24" />
							</ViewMoreButton>
						</ButtonContainer>
					}
					unCollapseButton={
						<ButtonContainer>
							<ViewMoreButton>
								<span>View More</span>
								<DownArrowIcon />
							</ViewMoreButton>
						</ButtonContainer>
					}
				/>
				<Divider />
				<FooterExtraLinkContent>
					<LinkWrapper>
						<LinkText isLight={isLight}>Links</LinkText>
						<Text onClick={() => trackPageLinkClick("Download Apna App")}>
							<Link href="/download-apna-app" passHref>
								<a rel="noopener noreferrer">Download Apna App</a>
							</Link>
						</Text>
						<Text onClick={() => trackPageLinkClick("Free Job Alerts")}>
							<Link href="/free-job-alerts" passHref>
								<a rel="noopener noreferrer">Free Job Alerts</a>
							</Link>
						</Text>
						<Text>
							<a
								href="https://careers.apna.co/"
								rel="noopener noreferrer"
								onClick={() => trackPageLinkClick("Contact Us")}
							>
								Careers
							</a>
						</Text>
						<Text onClick={() => trackPageLinkClick("Contact Us")}>
							<Link href="/contact-us" passHref>
								<a rel="noopener noreferrer">Contact Us</a>
							</Link>
						</Text>
						<Text
							onClick={() =>
								trackPageLinkClick("Vulnerability Disclosure Policy")
							}
						>
							<Link href="/vulnerability-disclosure-policy" passHref>
								<a rel="noopener noreferrer">
									Vulnerability Disclosure Policy
								</a>
							</Link>
						</Text>
						<Text onClick={() => trackPageLinkClick("International Jobs")}>
							<Link
								href={`/international/jobs${
									isUserLoggedIn() ? `?c_id=${isUserLoggedIn()}` : ""
								}`}
								passHref
							>
								<a rel="noopener noreferrer">International Jobs</a>
							</Link>
						</Text>
					</LinkWrapper>

					<LinkWrapper>
						<LinkText>Legal</LinkText>
						<Text onClick={() => trackPageLinkClick("Privacy Policy")}>
							<Link href="/privacy" passHref>
								<a>Privacy Policy</a>
							</Link>
						</Text>
						<Text onClick={() => trackPageLinkClick("Terms & Conditions")}>
							<Link href="/user-agreement" passHref>
								<a>User Terms & Conditions</a>
							</Link>
						</Text>
						<Text onClick={() => trackPageLinkClick("Terms of Service")}>
							<Link href="/terms-of-service" passHref>
								<a>Employer Terms of Service</a>
							</Link>
						</Text>
						<Text>
							<a
								onClick={() => trackPageLinkClick("Employer FAQs")}
								href="https://apna.co/employer-help-center/hc"
								target="_blank"
								rel="noopener noreferrer"
							>
								Employer FAQs
							</a>
						</Text>
						<Text onClick={() => trackPageLinkClick("Community Guidelines")}>
							<Link href="/community-guidelines" passHref>
								<a>Community Guidelines</a>
							</Link>
						</Text>
					</LinkWrapper>
					<LinkWrapper>
						<LinkText>Resources</LinkText>
						<Text>
							<a
								href="https://apna.co/career-central/"
								onClick={() => trackPageLinkClick("Blog")}
								target="_blank"
								rel="noopener noreferrer"
							>
								Blog
							</a>
						</Text>
						<Text onClick={() => trackPageLinkClick("Sitemap")}>
							<Link href="/api/sitemap-index.xml" passHref>
								<a rel="noopener noreferrer">Sitemap</a>
							</Link>
						</Text>
					</LinkWrapper>
				</FooterExtraLinkContent>
			</FooterSection>
			{showNewFooter ? (
				<FooterSocialContainer source={source} isDark showQR />
			) : (
				<FooterSocialContainerV2 source={source} />
			)}
			<BrandLines />
		</>
	);
};

const LinkText = styled.div`
	font-size: 14px;
	font-weight: 700;
	color: #f5f7fb;

	${bp.md} {
		font-size: 14px;
		line-height: 18px;
		margin-bottom: 14px;
	}
`;

const ViewMoreButton = styled.button`
	background: inherit;
	border: none;
	outline: none;
	font-size: 14px;
	font-weight: 18px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #f5f7fb;
	height: 28px;
	cursor: pointer;

	${bp.md} {
		font-size: 12px;
		height: 30px;
	}

	span {
		margin-right: 4px;
	}
`;

const FooterSection = styled.footer`
	padding: 42px 20px 60px;
	background-color: #4d3951;
	border-top: 2px solid #e8e7ea;
	a,
	${LinkText} {
		color: ${({ isLight }) => (isLight ? "#190A28" : "#f5f7fb")} !important;
	}

	${ViewMoreButton} {
		color: ${({ isLight }) => (isLight ? "#190A28" : "#f5f7fb")} !important;

		& path {
			stroke: ${({ isLight }) => (isLight ? "#190A28" : "#f5f7fb")} !important;
		}
	}

	${bp.md} {
		padding: 72px 80px 90px;
	}

	${bp.lg} {
		padding: 72px 240px 90px;
	}
`;

const FooterLinkContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;

	${bp.md} {
		grid-template-columns: 1fr 1fr 1fr;
	}
`;

const LinkWrapper = styled.div``;

const Text = styled.div`
	margin-right: 15px;
	margin-top: 2px;
	font-size: 12px;
	line-height: 16px;
	& a {
		text-decoration: none;
		color: ${({ isLight }) => (isLight ? "#190A28" : "#f5f7fb")};
	}
`;

const ButtonContainer = styled.div`
	margin: 14px 0 35px;
	display: flex;
	justify-content: center;
	align-items: center;

	${bp.md} {
		margin: 16px 0 0;
	}
`;

const Divider = styled.hr`
	display: none;

	${bp.md} {
		display: block;
		border-top: 0.5px solid rgba(255, 255, 255, 0.2);
		height: 0;
		margin: 16px 0 40px;
	}
`;

const FooterExtraLinkContent = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
`;

export default ResponsiveFooterDarkV2;
